<template>
  <div>
    <span v-if="status === 'online'" :title="$t('Online')">
      <span class="indicator online">
        <span class="blink"></span>
      </span>
      {{ showText ? $t('Online') : '' }}
    </span>
    <span v-else :title="$t('Offline')">
      <span class="indicator offline"></span>
      {{ showText ? $t('Offline') : '' }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    showText: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      required: true,
      default: 'offline'
    }
  }
}
</script>

<style lang="scss" scoped>
.indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 2px;
  border-radius: 50%;
  position: relative;
  font-size: 14px;
  @media (max-width: 767px) {
    width: 8px;
    height: 8px;
  }
}
span.blink {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #0fcc45;
  opacity: 0.7;
  border-radius: 50%;
  animation: blink 1s linear infinite;
}
.online {
  background-color: #0fcc45;
}
.offline {
  background-color: #f00;
}
@keyframes blink {
  100% { transform: scale(2, 2);
    opacity: 0;
  }
}
</style>
