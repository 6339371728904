<template>
  <b-col lg="6" md="12" class="call-card">
    <div class="call-card-inner">
      <div class="call-left">
        <feather-icon
            v-if="call.duration === 0"
            icon="XOctagonIcon"
            size="16"
            class="mr-1"
            style="color: #d33535;"
        />
        <feather-icon
            v-if="call.duration !== 0"
            icon="CheckIcon"
            size="16"
            class="mr-1"
            style="color: #5ad52b;"
        />
        <router-link :to="{name: 'users.show', params: {id: call.user.userId}}">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="48"
                  :src="call.user.profileImage"
                  :text="avatarText(call.user.userName)"
              />
            </template>
          </b-media>
        </router-link>
        <div class="call-details">
          <router-link :to="{name: 'users.show', params: {id: call.user.userId}}" class="call-link">
            @{{ call.user.userName }}
          </router-link>
          <div class="call-time" v-if="!hideTime">
            {{ getDate(call.time) }} {{ getDateForHuman(call.duration) }}
          </div>
        </div>
      </div>
      <div class="call-right">
        <div class="call-again" v-if="callType === 'voice'" @click="callUser(call.user)">
          <b-img :src="iconPhone" height="16" width="16"/>
        </div>
        <div class="call-again" v-if="callType === 'video'" @click="callVideoUser(call.user)">
          <b-img :src="iconVideoCall" height="16" width="16"/>
        </div>
        <div class="call-type">
          {{ call.type  === 'incoming' ? $t('Incoming call') : $t('Outgoing call') }}
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import { BAvatar, BCol, BImg, BMedia } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {showNotify, throwDefaultError} from '@/helpers/helpers'
import moment from 'moment/moment'
import { avatarText } from '@core/utils/filter'
import _ from "lodash";

export default {
  name: 'call-card',
  props: {
    call: {
      type: Object,
      required: true,
    },
    callType: {
      type: String,
      default: 'voice',
    },
    hideTime: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BAvatar,
    BCol,
    BImg,
    BMedia
  },
  data() {
    return {
      iconPhone: require('@/assets/images/icons/phone-green.svg'),
      iconVideoCall: require('@/assets/images/icons/video-call2.svg'),
    }
  },
  methods: {
    avatarText(text) {
      return avatarText(text)
    },
    callUser(user) {
      this.$emit('setLoading', true)
      useJwt.getUser(user.userId)
          .then(response => {
            this.$store.dispatch('app/setModalCallUser', response.data.body)
            this.$store.dispatch('app/setModalCall', true)
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.$emit('setLoading', false)
          })
      // if (!this.$store.getters['auth/userPhoneNumber']) {
      //   this.$store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
      //   this.$store.dispatch('app/setModalAddPhoneNumber', true)
      //   this.$store.dispatch('app/setVerificationType', 'phone')
      // } else {
      //   this.$emit('setLoading', true)
      //   useJwt.callUser(user.id)
      //       .then(response => {
      //         showNotify(this.$toast, 'Arama başarılı', 'success')
      //       })
      //       .catch(error => {
      //         const { response } = error
      //         throwDefaultError(this.$toast, error)
      //         if (response && response.data?.header?.error?.errorCode == 215) {
      //           this.$store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
      //           this.$store.dispatch('app/setModalAddPhoneNumber', true)
      //           this.$store.dispatch('app/setVerificationType', 'phone')
      //         }
      //       })
      //       .finally(() => { this.$emit('setLoading', true) })
      // }
    },
    callVideoUser(user) {
      this.$emit('setLoading', true)
      useJwt.getUser(user.userId)
          .then(response => {
            this.$store.dispatch('app/setModalCallType', 'video')
            this.$store.dispatch('app/setModalCall', false)
            setTimeout(() => {
              this.$store.dispatch('app/setModalCallUser', response.data.body)
              this.$store.dispatch('app/setModalCall', true)
            }, 33)
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.$emit('setLoading', false)
          })
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    getDateForHuman(timeLeft) {
      if (timeLeft <= 0) {
        return '0 ' + this.$t('seconds')
      }

      let result = ''
      let totalSeconds = timeLeft;

      const days = Math.floor(totalSeconds / (60 * 60 * 24))
      totalSeconds -= days * (60 * 60 * 24)

      const hours = Math.floor(totalSeconds / (60 * 60))
      totalSeconds -= hours * (60 * 60)

      const minutes = Math.floor(totalSeconds / (60))
      totalSeconds -= minutes * 60

      const seconds = Math.floor(totalSeconds % 60)

      if (days > 0) {
        result += days + ` ${this.$t('days')}, `
      }

      if (hours > 0) {
        result += hours + ` ${this.$t('hours')}, `
      }

      if (minutes > 0) {
        result += minutes + ` ${this.$t('minutes')}, `
      }

      if (seconds > 0) {
        result += seconds + ` ${this.$t('seconds')}, `
      }

      return _.trimEnd(result, ', ')
    }
  }
}
</script>

<style lang="scss" scoped>
.call-card {
  background-color: #F5F8FD;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;

  .call-card-inner {
    display: flex;
    justify-content: space-between;

    .call-left {
      display: flex;
      align-items: center;

      .call-details {
        .call-link {
          font-family: 'Lexend', sans-serif;
          font-size: 18px;
          font-style: normal;
          color: #000000;
          font-weight: 600;
          line-height: 22px;
        }
      }
      .call-time {
        font-family: 'Lexend', sans-serif;
        color: #A4A4A4;
        margin-top: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

      }
    }

    .call-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .call-again {
        padding: 6px;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
      }
      .call-type {
        font-family: 'Lexend', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #A4A4A4;
      }
    }
  }
}
</style>
