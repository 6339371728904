<template>
  <b-col class="text-center mt-2" offset-xl="4" xl="4" offset-lg="4" lg="4" offset-md="3" md="6" sm="12">
    <slot>
      <h3 class="color-black">
        {{ $t('Discover no user title') }}
      </h3>
      <p class="color-gray fs-16" v-html="$t('Discover no user description')" />
    </slot>
    <b-img v-if="showImage" fluid :src="imageDiscoverNoUser" />
  </b-col>
</template>

<script>
import { BCol, BImg } from 'bootstrap-vue'

export default {
  props: {
    showImage: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    BCol,
    BImg
  },
  data () {
    return {
      imageDiscoverNoUser: require('@/assets/images/pages/discover-no-user.png'),
    }
  }
}
</script>
